<template>
  <!-- saleteam-area -->
  <div class="saleteam-area">
    <!-- teams -->
    <div class="teams" v-for="member in members" :key="member.id">
      <div
        class="delete-member blue-circle"
        v-on:click="deleteMemberModal(member)"
      >
        <img class="img-fluid" src="../../assets/img/Delete.svg" alt="" />
      </div>
      <div class="edit-member blue-circle" v-on:click="updateMember(member)">
        <img class="img-fluid" src="../../assets/img/pencil.svg" alt="" />
      </div>
      <div class="team-img">
        <img v-if="member.picture" class="img-fluid" :src="member.picture" />
        <img
          v-else
          class="img-fluid"
          src="../../assets/img/profile.svg"
          alt=""
        />
      </div>

      <div class="team-title">
        <h3>{{ member.name }}</h3>
        <!-- <p>{{member.tm_name}}</p> -->
        <p>{{ member.roleTitle }}</p>
        <div
          class="member-status"
          :class="member.user_status == 1 ? 'text-success' : 'text-danger'"
        >
          <span v-if="member.user_status == 1">Active</span>
          <span v-if="member.user_status == 0">InActive</span>
        </div>
        <p>{{ member.created | formatDate("MMMM DD, YYYY") }}</p>
        <p class="mt-2">
          <router-link
            :to="{ path: '/reports/agents', query: { agent: member.id } }"
            class="add-appoi mx-1"
            >View Report</router-link
          >
          <!-- <a
            style="cursor: pointer"
            @click="SmtpModal(member.id)"
            class="email-settings"
            >Email Settings</a
          > -->

          <router-link
            :to="{ name: 'weeklySchedule', query: { agent: member.id } }"
            class="change-pass mx-1"
            >Manage Work Hours</router-link
          >
        </p>
      </div>

      <div class="team-tags">
        <p>Permission</p>
        <simplebar class="permissions-section" :id="'myMemElement' + member.id">
          <ul v-if="member.permissions_title">
            <comaSplit
              v-for="(tag, i) in member.permissions_title.split(',')"
              :key="i"
              :tag="tag"
            ></comaSplit>
          </ul>
        </simplebar>
      </div>
    </div>
    <!-- teams -->

    <!-- teams -->
    <div class="teams">
      <div class="add-new">
        <a
          href="#"
          data-toggle="modal"
          data-target="#addMemberModal"
          data-backdrop="static"
          data-keyboard="false"
          ><img class="img-fluid" src="../../assets/img/240.svg" alt=""
        /></a>
      </div>
    </div>
    <!-- teams -->

    <!-- Email Settings -->
    <div
      class="modal fade"
      id="emailSettingModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="updateMemberModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="emailSettingModal">Smtp Details</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="addSmtp">
            <div class="modal-body">
              <div class="row add-member">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Driver</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Driver"
                      v-model="smtp.driver"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Outgoing Mail (SMTP) Server</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="smtp.out_host"
                      placeholder="Host"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.smtp.out_host.$hasError === true"
                    >
                      {{ $vd.smtp.$errors.out_host[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Outgoing Port</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="smtp.out_port"
                      placeholder="Outgoing Port"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Incoming Mail (IMAP) Server</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="smtp.in_host"
                      placeholder="Host"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Incoming Port</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="smtp.in_port"
                      placeholder="Incoming Port"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="smtp.username"
                      placeholder="Username"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="smtp.password"
                      placeholder="Password"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Encryption</label>
                    <select class="form-control" v-model="smtp.encryption">
                      <option value="tls">tls</option>
                      <option value="ssl">ssl</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" v-if="!doCrop" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Update
              </button>
              <a
                href="#"
                v-if="doCrop"
                v-on:click="cancelCrop"
                data-dismiss="modal"
                class="add-btn"
                ><img
                  class="img-fluid"
                  src="../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <!-- <button type="button" v-on:click="cropIt" class="rem-btn" v-if="doCrop">Crop</button> -->
              <a v-on:click="cropIt" class="rem-btn" v-if="doCrop">Crop</a>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--Email Settings-->

    <div
      class="modal fade"
      id="updateMemberModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="updateMemberModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="updateMemberModalLabel">
              Update Member
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="updateMemberProcess">
            <div class="modal-body">
              <div v-show="doCrop">
                <vue-cropper
                  :aspect-ratio="1 / 1"
                  :view-mode="1"
                  ref="cropper"
                  :src="cropImgUrl"
                  alt="Source Image"
                ></vue-cropper>
              </div>
              <!-- <div class="user-img">
                            <div class="button-wrapper">
                                <span class="label">
                                <img class="img-fluid" v-bind:src="imgUrl"  alt="" >
                                </span><input type="file" ref="file" accept="image/*" v-on:change="handleFileUpload" class="upload upload-box" placeholder="Upload File">
                            </div>
                            <div class="edit-pro">
                                <img class="img-fluid" src="../../assets/img/edit.svg" alt="">
                            </div>
                        </div> -->
              <div v-show="!doCrop">
                <div class="user-img">
                  <div class="button-wrapper">
                    <span class="label">
                      <template v-if="updMember_.img">
                        <img
                          style="width: 100px; height: 100px"
                          class="img-fluid"
                          :src="updMember_.img"
                          alt=""
                        />
                      </template>
                      <template v-else>
                        <img class="img-fluid" v-bind:src="imgUrl" alt="" />
                      </template> </span
                    ><input
                      type="file"
                      ref="file"
                      accept="image/*"
                      v-on:change="handleFileUpload"
                      class="upload upload-box"
                      placeholder="Upload File"
                    />
                  </div>
                  <!-- <div class="edit-pro">
                                        <img class="img-fluid" src="../../assets/img/edit.svg" alt="">
                                    </div> -->
                </div>
                <div class="add-member">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label>First Name</label>
                      <input
                        v-model="updMember_.first_name"
                        type="text"
                        class="form-control"
                      />
                      <div
                        class="validate-error-message"
                        v-if="$vd.updMember_.first_name.$hasError === true"
                      >
                        {{ $vd.updMember_.$errors.first_name[0] }}
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Last Name</label>
                      <input
                        v-model="updMember_.last_name"
                        type="text"
                        class="form-control"
                      />
                      <div
                        class="validate-error-message"
                        v-if="$vd.updMember_.last_name.$hasError === true"
                      >
                        {{ $vd.updMember_.$errors.last_name[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Email Address</label>
                    <input
                      v-model="updMember_.email"
                      type="email"
                      class="form-control"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.updMember_.email.$hasError === true"
                    >
                      {{ $vd.updMember_.$errors.email[0] }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Phone number</label>
                    <!-- <vue-tel-input v-model="updMember_.phone" v-bind="{mode:'international',dynamicPlaceholder:true,inputId:'up_phone',enabledCountryCode:false,maxLen:15,validCharactersOnly:true,onlyCountries:['us'],defaultCountry:'us',placeholder:'Enter a phone number'}" ></vue-tel-input> -->
                    <input
                      type="tel"
                      v-model="updMember_.phone"
                      class="form-control"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.updMember_.phone.$hasError === true"
                    >
                      {{ $vd.updMember_.$errors.phone[0] }}
                    </div>
                    <!-- <input v-model="updMember_.phone" type="text" class="form-control" id="phone" placeholder="+123-22-223-00"> -->
                  </div>
                  <div class="form-group">
                    <label>Role</label>
                    <select
                      class="form-control"
                      v-model="updMember_.role_title"
                    >
                      <option
                        v-for="role in roles"
                        :key="role.id"
                        :value="role.id"
                      >
                        {{ role.role_title }}
                      </option>
                    </select>
                    <div
                      class="validate-error-message"
                      v-if="$vd.updMember_.role_title.$hasError === true"
                    >
                      {{ $vd.updMember_.$errors.role_title[0] }}
                    </div>
                  </div>

                  <div class="form-group">
                    <multi-select
                      :searchable="true"
                      id="up_lead_permissions"
                      textColumn="text"
                      :alreadySelected="updMember_.permissions"
                      :options="permissions"
                      v-model="updMember_.permissions"
                      label="Permissions"
                      placeholder="Add permissions"
                    ></multi-select>
                  </div>
                  <div class="form-group">
                    <multi-select
                      :searchable="true"
                      id="up_lead_source"
                      textColumn="ls_name"
                      placeholder="Select your lead source"
                      v-model="updMember_.lsources"
                      :alreadySelected="updMember_.sources"
                      :options="LSources"
                      label="Lead source"
                    ></multi-select>
                    <div
                      class="validate-error-message"
                      v-if="$vd.updMember_.lsources.$hasError === true"
                    >
                      {{ $vd.updMember_.$errors.lsources[0] }}
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Password</label>
                    <input
                      type="text"
                      v-model="updMember_.password"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <label>Status</label>
                    <select class="form-control" v-model="updMember_.status">
                      <option value="1">Active</option>
                      <option value="0">InActive</option>
                    </select>
                  </div>

                  <!-- <div class="form-group">
                                <label for="team_id">Team</label>
                                <Select2 id="team_id" v-model="updMember_.team_id" :options="teams" :settings="{ placeholder: 'Choose Team',containerCssClass:'select2-custom-container' }" />
                            </div> -->

                  <!-- <div class="form-group">
                                <div class="Permis-btns">
                                    <a class="Permis-temp" href="#">Temporarily suspended</a>
                                    <a class="Permis-assing" href="#" data-toggle="modal" data-target="#exampleModal2" data-dismiss="modal" aria-label="Close">Assign</a>
                                </div>
                            </div> -->
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" v-if="!doCrop" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Update
              </button>
              <a
                href="#"
                v-if="doCrop"
                v-on:click="cancelCrop"
                data-dismiss="modal"
                class="add-btn"
                ><img
                  class="img-fluid"
                  src="../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <!-- <button type="button" v-on:click="cropIt" class="rem-btn" v-if="doCrop">Crop</button> -->
              <a v-on:click="cropIt" class="rem-btn" v-if="doCrop">Crop</a>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="delAgent"
      role="dialog"
      aria-labelledby="customeralredyexist"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Before deleting assign this agent leads to:
            </h5>
            <a
              class="delas-colse close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <br /><br />
          <form @submit.prevent="deleteMember">
            <div class="modal-body">
              <div class="perfor-search">
                <div class="form-row">
                  <div class="form-group add-member col-md-12">
                    <autocomplete
                      ref="autocomplete"
                      placeholder="Search Agent"
                      :source="base_url + 'getemployeesbyagent' + '/'"
                      results-property="data"
                      :results-display="formattedAutocompleteDisplay"
                      :request-headers="autocompleteAuthHeaders"
                      @selected="selectCustomer"
                    >
                    </autocomplete>
                    <!-- <input type="search" class="form-control" id="exampleInputEmail1" placeholder="Seach by agent or team " /><img class="img-fluid per-sericon" src="../../assets/img/search.svg" alt="" /> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a @click="deleteEntireAgent" class="add-btn">
                Delete this agent entirely</a
              >
              <button type="submit" class="rem-btn">
                Delete this agent but keep the report records
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- saleteam-area -->
</template>

<script>
import axios from "axios";
import comaSplit from "../helpers/comaSplit";
import $ from "jquery";
import VueDaval from "vue-daval";
import MultiSelect from "../helpers/MultiSelectWithCheckbox";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import VueCropper from "vue-cropperjs";
import Autocomplete from "vuejs-auto-complete";
export default {
  name: "MembersGrid",
  mixins: [VueDaval],
  props: ["LSources", "permissions"],
  components: { comaSplit, MultiSelect, simplebar, VueCropper, Autocomplete },
  data() {
    return {
      search: "",
      newagentid: "",
      base_url: axios.defaults.baseURL,
      agentid: "",
      smtp: {
        driver: "smtp",
      },
      members: [],
      updMember_: {
        id: "",
        img: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        role_title: "",
        status: 1,
        permissions: [],
        lsources: [],
        sources: [],
        team: this.$route.params.slug,
        password: "",
      },
      team_name: "",
      imgUrl: require("../../assets/img/profile.svg"),
      cropImgUrl: null,
      doCrop: false,
      team_id: null,
      roles: [],
    };
  },
  vdRules: {
    updMember_: {
      first_name: { required: true },
      last_name: { required: true },
      email: { required: true, type: "email" },
      phone: { required: true },
      role_title: { required: true },
      lsources: { required: true },
    },
    smtp: {
      out_host: { required: true },
      out_port: { required: true },
      in_host: { required: true },
      in_port: { required: true },
      username: { required: true },
      password: { required: true },
      encryption: { required: true },
    },
  },
  created() {
    this.getTeamMembers();
    this.getRoles();
    // this.getSmtp();
    let vm = this;
    this.$root.$on("newMember", function () {
      vm.getTeamMembers();
    });
  },
  mounted() {
    let vm = this;
    this.$root.$on("edit_checkallpermisssions", function () {
      vm.updMember_.permissions = vm.permissions;
    });

    this.$root.$on("edit_unselectpermisssions", function () {
      vm.updMember_.permissions = [];
    });

    this.$root.$on("edit_unselectallsources", function () {
      vm.updMember_.lsources = [];
    });
    this.$root.$on("edit_checkallsources", function () {
      vm.updMember_.lsources = vm.LSources;
    });
  },
  watch: {
    "$route.params.slug": function () {
      this.getTeamMembers();
    },
  },
  computed: {
    autocompleteAuthHeaders() {
      return { Authorization: "Bearer " + this.$storage.get("auth").token };
    },
  },
  methods: {
    deleteEntireAgent() {
      axios
        .post(
          "delete_memberentire",
          { id: this.agentid, agent: this.newagentid },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.$toast.open({
              position: "top-right",
              message: "Agent Deleted",
              type: "success",
            });
            $("#delAgent").modal("hide");
            $(".autocomplete__inputs input").val("");
            this.getTeamMembers();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formattedAutocompleteDisplay(result) {
      return result.text;
    },
    selectCustomer(result) {
      console.log(result.selectedObject);
      this.newagentid = result.selectedObject.id;
    },
    deleteMember() {
      if (this.newagentid == "") {
        this.$toast.open({
          position: "top-right",
          message: "Please select agent to assign leads",
          type: "info",
        });
      } else {
        axios
          .post(
            "delete_member",
            { id: this.agentid, agent: this.newagentid },
            {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            }
          )
          .then((res) => {
            if (res.data.success) {
              this.$toast.open({
                position: "top-right",
                message: "Agent Deleted",
                type: "success",
              });
              $("#delAgent").modal("hide");
              $(".autocomplete__inputs input").val("");
              this.getTeamMembers();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    SmtpModal(id) {
      this.smtp.user_id = id;
      this.user_id = id;
      axios
        .get("/get/smpt?for=" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            this.smtp = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      $("#emailSettingModal").modal("show");
    },

    getTeamMembers() {
      axios
        .get("teamMembers/" + this.$route.params.slug, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.error) {
            if (data.error == "no_access") {
              this.$router.push("/dashboard");
            }
          } else {
            this.members = data.success.members;
            console.log(this.members);
            this.team_name = data.success.team_name;
            // this.$root.$emit("pTitle", this.team_name);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateMember(member) {
      //console.log(member)
      // var name;
      // if (
      //   member.name != null &&
      //   member.name != undefined &&
      //   member.name != "undefined" &&
      //   member.name != ""
      // ) {
      //   name = member.name.split(/\s+/);
      // } else {
      //   name = "";
      // }
      // var perm_title;
      var perm_ids;
      if (
        member.permissions_title != null &&
        member.permissions_title != undefined &&
        member.permissions_title != "undefined" &&
        member.permissions_title != ""
      ) {
        //perm_title = member.permissions_title.split(/\s+/);
        //perm_ids = member.permissions.split(/\s+/);
        // perm_title = member.permissions_title.split(',');
        perm_ids = member.permissions.split(",");
      }
      //else{
      //     perm_title = '';
      // }

      let cus_permission = [];
      $.each(this.permissions, function (key, value) {
        cus_permission["id" + value.id] = value.text;
      });

      let permissions = [];
      if (
        perm_ids != null &&
        perm_ids != undefined &&
        perm_ids != "undefined" &&
        perm_ids != ""
      ) {
        //if (perm_ids.length > 0) {
        $.each(perm_ids, function (key, value) {
          permissions.push({ id: value, text: cus_permission["id" + value] });
          //permissions.push({id:value,text:perm_title[key]});
        });
      }
      var src;
      if (
        member.sources != null &&
        member.sources != undefined &&
        member.sources != "undefined" &&
        member.sources != ""
      ) {
        src = member.sources.split(",");
      } else {
        src = "";
      }
      let sources = [];
      if (src.length > 0) {
        $.each(src, function (key, value) {
          var s = value.split("|");
          sources.push({ ls_id: parseInt(s[0]), ls_name: s[1] });
        });
      }
      console.log(member.sources);
      this.updMember_.id = member.id;
      this.updMember_.first_name = member.first_name;
      this.updMember_.last_name = member.last_name;
      this.updMember_.permissions = permissions;
      this.updMember_.email = member.email;
      this.updMember_.phone = member.phone;
      this.updMember_.role_title = member.role_id;
      this.updMember_.sources = sources;
      console.log(this.updMember_.sources);
      this.updMember_.status = member.user_status;
      this.updMember_.img = member.picture;
      $("#updateMemberModal").modal("show");
    },
    updateMemberProcess() {
      console.log(this.updMember_);
      this.$vd.updMember_
        .$validate()
        .then(() => {
          axios
            .post("update_member", this.updMember_, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.error) {
                this.$toast.open({
                  position: "top-right",
                  message: res.data.error,
                  type: "info",
                });
                return false;
              }
              if (res.data.success) {
                $("#updateMemberModal").modal("hide");
                this.getTeamMembers();
                this.updMember_ = {
                  id: "",
                  img: "",
                  first_name: "",
                  last_name: "",
                  email: "",
                  phone: "",
                  role_title: "",
                  status: 1,
                  permissions: [],
                  lsources: [],
                  sources: [],
                  team: this.$route.params.slug,
                };
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    addSmtp() {
      console.log();
      this.smtp.for = "member";
      this.smtp.user_id = this.user_id;
      this.$vd.smtp
        .$validate()
        .then(() => {
          axios
            .post("/add/smpt", this.smtp, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                $("#emailSettingModal").modal("hide");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    deleteMemberModal(member) {
      this.agentid = member.id;
      $("#delAgent").modal("show");
      // this.$confirm({
      //   title: `Are you sure?`,
      //   message: "you want to delete this user?",
      //   button: {
      //     no: "No",
      //     yes: "Yes",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       //console.log(member)
      //       axios
      //         .post(
      //           "delete_member",
      //           { id: member.id },
      //           {
      //             headers: {
      //               Authorization: "Bearer " + this.$storage.get("auth").token,
      //             },
      //           }
      //         )
      //         .then((res) => {
      //           if (res.data.success) {
      //             this.getTeamMembers();
      //           }
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });
      //     }
      //   },
      // });
    },
    handleFileUpload(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgUrl = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.doCrop = true;

      //this.imgUrl = URL.createObjectURL(this.$refs.file.files[0]);
    },
    cropIt() {
      this.cropImgUrl = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.updMember_.img = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.doCrop = false;
    },
    cancelCrop() {
      this.doCrop = false;
    },
    getRoles() {
      axios
        .get("roles", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.roles = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>