<template>
  <DashboardContainer>
    <MembersGrid :LSources="leadSources" :permissions="lPermissions" />
    <AddTeamMember :LSources="leadSources" :permissions="lPermissions" />
  </DashboardContainer>
</template>

<script>
import DashboardContainer from "../containers/DashboardContainer";
import MembersGrid from "../components/teams/MembersGrid";
import AddTeamMember from "../components/teams/AddTeamMember";
import axios from "axios";

export default {
  name: "Teams",
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  data() {
    return {
      leadSources: [],
      lPermissions: [],
    };
  },
  components: {
    DashboardContainer,
    MembersGrid,
    AddTeamMember,
  },
  created() {
    this.getLeadSource();
    this.getPermissions();
  },
  methods: {
    getLeadSource() {
      axios
        .get("/dealer/lead/sources", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.leadSources = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPermissions() {
      axios
        .get("permissions", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.lPermissions = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>